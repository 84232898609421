<template>
  <div class="app-container">
    <div class="searchForm_box">
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item>
          <el-input
            placeholder="請輸入內容"
            v-model="searchForm.searchTxt"
            class="input-with-select"
          >
            <el-select
              v-model="searchForm.searchChoose"
              slot="prepend"
              placeholder="請選擇"
              style="width: 130px"
            >
              <el-option label="商品ID" value="goods_no"></el-option>
              <el-option label="商品名稱" value="goods_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索
          </el-button>
          <!-- <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
            >重置
          </el-button> -->
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            >新增
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      row-key="Id"
      :data="barcodeList"
      border
      class="table"
      v-loading="loading"
    >
      <el-table-column label="商品圖片" prop="img" width="90">
        <template slot-scope="scope">
          <el-image
            style="width: 50px; height: 50px"
            :src="
              scope.row.img != '' && scope.row.img != null
                ? scope.row.img
                : require('@/assets/image/default.jpg')
            "
            fit="contain"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        label="商品名稱"
        prop="goods_name"
        width="250"
      ></el-table-column>
      <el-table-column label="商品條碼" prop="goods_no"></el-table-column>
      <el-table-column label="規格" prop="spec"></el-table-column>
      <el-table-column label="品牌" prop="brand"></el-table-column>
      <el-table-column label="單位" prop="unit"></el-table-column>
      <el-table-column label="價格" prop="price"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            >修改
          </el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            >刪除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!-- 添加或修改部門對話框 -->
    <el-dialog
      custom-class="dialog_goods"
      :title="title"
      :visible.sync="open"
      width="600px"
      append-to-body
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名稱" prop="goods_name">
              <el-input
                v-model="form.goods_name"
                placeholder="請輸入商品名稱"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品條碼" prop="goods_no">
              <el-input
                v-model="form.goods_no"
                controls-position="right"
                placeholder="請輸入商品條碼"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="規格" prop="spec">
              <el-input
                v-model="form.spec"
                placeholder="請輸入規格"
                maxlength="20"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="品牌" prop="brand">
              <el-input
                v-model="form.brand"
                placeholder="請輸入品牌"
                maxlength="11"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="單位" prop="unit">
              <el-input
                v-model="form.unit"
                placeholder="請輸入單位"
                maxlength="50"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="價格" prop="price">
              <el-input
                v-model="form.price"
                placeholder="請輸入價格"
                maxlength="50"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="圖片" prop="img">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :auto-upload="false"
            :on-change="fileUpload"
          >
            <img v-if="form.img" :src="form.img" style="width: 150px" />
            <div v-else class="avatar-uploader-icon">
              <i class="el-icon-plus"></i>
              <p>上傳圖片</p>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">確 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listBarcode,
  editBarcode,
  delBarcode,
  addBarcode,
  uploadPic,
} from "@/api/barcode";

const img = require("@/assets/image/default.jpg");
export default {
  name: "list",
  data() {
    return {
      title: "",
      loading: true,
      searchForm: {
        searchTxt: "",
        searchChoose: "goods_no",
        goods_no: "",
        goods_name: "",
      },
      page: 1,
      pageSize: 50,
      total: 0,
      barcodeList: [],
      // 是否顯示彈出層
      open: false,
      // 表單參數
      form: {
        img: "",
      },
      // 表單校驗
      rules: {
        goods_name: [
          { required: true, message: "部門名稱不能為空", trigger: "blur" },
        ],
        goods_no: [
          { required: true, message: "顯示排序不能為空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    getBarcodeList() {
      let params = {
        page: this.page,
        pageSize: this.pageSize,
        goods_no:
          this.searchForm.searchChoose == "goods_no"
            ? this.searchForm.searchTxt
            : "",
        goods_name:
          this.searchForm.searchChoose == "goods_name"
            ? this.searchForm.searchTxt
            : "",
      };
      this.loading = true;
      listBarcode(params).then((response) => {
        this.barcodeList = response.data.list;
        this.total = response.data.count;
        this.loading = false;
      });
    },
    // 分頁
    handleSizeChange(val) {
      this.pageSize = val;
      this.getBarcodeList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getBarcodeList();
    },
    handleQuery() {
      this.getBarcodeList();
    },
    resetQuery() {
      this.searchForm = {
        searchChoose: "goods_no",
      };
    },
    /** 新增按鈕操作 */
    handleAdd(row) {
      this.reset();
      this.open = true;
      this.title = "添加商品條碼";
    },
    /** 提交按鈕 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id != undefined) {
            editBarcode(this.form)
              .then((response) => {
                this.$message.success("修改成功");
                this.open = false;
                this.getBarcodeList();
              })
              .catch(() => {});
          } else {
            addBarcode(this.form)
              .then((response) => {
                this.$message.success("添加成功");
                this.open = false;
                this.getBarcodeList();
              })
              .catch(() => {});
          }
        }
      });
    },
    /** 修改按鈕操作 */
    handleUpdate(row) {
      this.reset();
      this.open = true;
      this.title = "修改商品條碼";
      this.form = row;
    },
    /** 刪除按鈕操作 */
    handleDelete(row) {
      this.$modal
        .confirm('是否確認刪除條碼為"' + row.goods_no + '"的商品？')
        .then(function () {
          return delBarcode({ id: row.id });
        })
        .then(() => {
          this.getBarcodeList();
          this.$modal.msgSuccess("刪除成功");
        })
        .catch(() => {});
    },
    // 取消按鈕
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表單重置
    reset() {
      this.form = {
        id: undefined,
        goods_name: undefined,
        goods_no: undefined,
        price: undefined,
        brand: undefined,
        unit: undefined,
        spec: undefined,
        // status: "0",
      };
    },
    fileUpload(file) {
      const param = new FormData();
      param.append("file", file.raw);
      uploadPic(param)
        .then((response) => {
          this.form.img = response.data.url;
          this.$forceUpdate();
        })
        .catch(() => {});
    },
  },
  created() {
    this.getBarcodeList();
  },
};
</script>
<style lang="scss" scoped>
.agentManage {
  padding: 10px;

  > .searchForm_box {
    padding: 10px;
    background: #fff;
    margin: 10px 0;

    .el-form {
      .el-form-item {
        margin-bottom: 10px;
      }
    }
  }
}

.avatar-uploader-icon {
  color: #8c939d;
  width: 150px;
  height: 150px;
  text-align: center;
  padding: 40px 0 0 0;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
</style>
